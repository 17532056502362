import { isEmpty, get } from 'lodash-es';
import { CART_STEPS } from '../../pages/MultiCheckout/constants/multi-checkout.js';
import { CHECKOUT_EVENTS } from '../constants/angular-events';
import { BLOCK_STATUS } from '../constants/components/collapse-block-status';
import { COLLAPSE_STATUS, SECTION_STATUS } from '../constants/components/collapse-section-status';

SECTION_NAME = {
  ALL: 'all',
  ITEMS: 'items',
  DELIVERY: 'delivery',
  PROMOTIONS: 'promotions',
  DESTINATION: 'destination',
  SUMMARY: 'summary',
  FORM: 'form',
}

app.directive("cartPage", [
  '$compile'
  'checkoutService'
  'cartService'
  'gaService'
  'Analytics'
  '$timeout'
  'trackerService'
  'slFeatureService'
  '$rootScope'
  '$filter'
  'cart'
  'slPixelService'
  'merchantService'
  'ordersService'
  'mainConfig'
  'hiidoTrackerService'
  'staticImageHost'
  'fbService'
  'multiCheckoutService'
  (
    $compile
    checkoutService
    cartService
    gaService
    Analytics
    $timeout
    trackerService
    slFeatureService
    $rootScope
    $filter
    cart
    slPixelService
    merchantService
    ordersService
    mainConfig
    hiidoTrackerService
    staticImageHost
    fbService
    multiCheckoutService
  ) ->
    {
      restrict: 'A'
      link: (scope, element, attrs) ->
        scope.errors = {
          fieldErrors: []
        }
        scope.validateItem = {
          items: [],
          message: ''
        }
        scope.state = {
          isCartLoading: false
        }
        scope.multiCart = {
          currentStep: CART_STEPS.DELIVERY,
          itemsSectionCollapseStatus: COLLAPSE_STATUS.EXPANDED,
          summarySectionStatus: SECTION_STATUS.DEFAULT,
          errors: {},
        }
        scope.staticImageHost = staticImageHost;
        scope.clickFBLogin = (appId, version) ->
          fbService.fbLogin(appId, version).then((res) ->
            if res.authResponse
              window.location.reload();
          );

        if Analytics.configuration.enhancedEcommerce
          gaService.sendPageView()

        scope.isTrialPlan = merchantService.isTrialPlan();
        deviceCanUseApplePay = !!window.ApplePaySession

        trackerService.track({
          type: trackerService.generalEventType.LOAD_CART_PAGE,
        });

        getMultiCartErrors = (res) -> _.omit(res.data, 'all')

        setCartDeliveryErrors = () ->
          Object.entries(scope.multiCart.errors).forEach(([cart_tag_id, errors]) ->
            return if _.isEmpty(errors)

            $errorWrapper = element.find('.multi-cart-error__wrapper[data-cart-tag-id="' + cart_tag_id + '"]')
            $errorBlock = $errorWrapper.find('.multi-cart-error__block')
            $errorContent = Object.values(errors).map((err) ->
              $errorEl = element.find("#multi-cart-error__item-template").clone()
              $errorEl.find('.multi-cart-error__item-text').html(err)
              $errorEl.html()
            ).join('')

            $errorWrapper.removeClass('hidden')
            $errorBlock.html($errorContent)
            element.find('[block-id="' + cart_tag_id + '"] > .collapse-block').addClass(BLOCK_STATUS.ERROR)
          )

        element.on "click", ".btn-checkout", (event) ->
          if scope.isTrialPlan && !slFeatureService.hasFeature('trial_limit_whitelist')
            event.preventDefault()
            ordersService.allowTrialPlanOrders(mainConfig.merchantId).then((res) ->
              isAllowCheckout = res.data.allow_place_order
              if !isAllowCheckout || (isAllowCheckout && res.data.orders_counts == 0)
                # show trial plan popup
                $rootScope.currentModal = checkoutService.openTrialOrderLimitPopup(isAllowCheckout);
                $rootScope.currentModal.result.then(() ->
                  isAllowCheckout && window.location = '/checkout'
                )
              else
                window.location = '/checkout'
            )

          if ($('#order-payment-method').attr('attr-payment-method') == 'isNewWebApplePay' && !deviceCanUseApplePay)
            event.preventDefault()
            checkoutErrorDom = document.getElementById('checkout-errors')
            checkoutErrorDom.innerHTML = $filter('translate')('checkout.payments.apple_pay.not_supported')
            checkoutErrorDom.style.display = 'block'
            angular.element('html, body').animate({ scrollTop: angular.element("#checkout-container").offset().top }, 'slow')
            $('.btn-checkout').addClass('disabled')

          if scope.state.isCartLoading
            event.preventDefault()
          else if !cartService.isAllRedeemGift()
            items = $rootScope.currentCart.getItems()
            subtotal = $rootScope.currentCart.getSubtotal()
            trackerService.fbAddPaymentInfo(items, subtotal)
            trackerService.track({
              type: trackerService.generalEventType.ADD_BILLING,
              data: {
                items,
                subtotal,
              },
            })
            trackerService.track({
              type: trackerService.generalEventType.ADD_SHIPPING,
              data: {
                items,
                subtotal,
              },
            })
            hiidoTrackerService.orderEdit.proceedToCheckout()
            if Analytics.configuration.enhancedEcommerce
              gaService.setUserId()
              Analytics.trackCheckout(2,"InitateCheckout")
              Analytics.trackEvent('UX', 'initate_checkout', 'InitateCheckout', undefined, true)
            trackerService.track({
              type: trackerService.generalEventType.INITIATE_CHECKOUT,
              data: {
                items,
                subtotal,
              },
            })
          
          if multiCheckoutService.isEnabled()
            event.preventDefault()
            multiCheckoutService.validateDelivery().then(() -> 
              window.location = '/checkout'
            ).catch((err) ->
              scope.errors = err.data
              scope.multiCart.errors = getMultiCartErrors(err)
              setCartDeliveryErrors()
              setCartErrors()

              if scope.errors
                angular.element('html, body').animate({ scrollTop: angular.element("#checkout-container").offset().top }, 'slow')
            )

        reload = (keys) ->
          shouldReloadAll = keys is SECTION_NAME.ALL
          keys = [] if not keys?

          element.find('.shopping-cart .loading-icon, .cart-addon .loading-icon').show() if shouldReloadAll or _.intersection(keys, ["items", "promotions"]).length > 0
          element.find('.shopping-cart .cart-share-btn').addClass('disable')
          scope.$broadcast(CHECKOUT_EVENTS.CART.ITEMS.RELOAD) if shouldReloadAll or _.contains(keys, SECTION_NAME.ITEMS) or _.contains(keys, SECTION_NAME.PROMOTIONS)
          scope.$broadcast(CHECKOUT_EVENTS.CART.PROMOTIONS.RELOAD) if shouldReloadAll or _.contains(keys, SECTION_NAME.PROMOTIONS)
          scope.$broadcast(CHECKOUT_EVENTS.CART.DELIVERY.RELOAD) if shouldReloadAll or _.contains(keys, SECTION_NAME.DELIVERY)
          scope.$broadcast(CHECKOUT_EVENTS.CART.DESTINATION.RELOAD) if shouldReloadAll or _.contains(keys, SECTION_NAME.DESTINATION)

          if shouldReloadAll or _.contains(keys, SECTION_NAME.FORM)
            element.find(".order-form .loading-icon").show()
            scope.$broadcast(CHECKOUT_EVENTS.CART.FORM.RELOAD)

          if shouldReloadAll or _.contains(keys, SECTION_NAME.SUMMARY)
            element.find(".order-summary .loading-icon").show()
            scope.$broadcast(CHECKOUT_EVENTS.CART.SUMMARY.RELOAD)

        validateCart = (callback) ->
          validateRequest()
            .then ((res) ->
              scope.errors = {}
              scope.multiCart.errors = {}
            )
            .catch ((res) ->
              scope.errors = res.data
              scope.multiCart.errors = getMultiCartErrors(res)

              if scope.errors
                angular.element('html, body').animate({ scrollTop: angular.element("#checkout-container").offset().top }, 'slow')
            )
            .finally () ->
              validateLocalData()
              setCartErrors()
              callback() if callback?

        validateRequest = () ->
          adaptValidateData = (res) ->
            if multiCheckoutService.isEnabled() then { data: res.data } else { data: { all: res.data }}
          checkoutService.requestValidate()
            .then((res) -> adaptValidateData(res))
            .catch((res) -> throw adaptValidateData(res))

        validateLocalData = () ->
          scope.errors.fieldErrors = []
          angular.element(".form-group.has-error").removeClass("has-error")
          checkoutService.getFormFields(element.find("form"), scope)
            .each ($field) ->
              scope.errors.fieldErrors.push($field.attr('name')) if checkoutService.validateFormField($field)

        setCartErrors = () ->
          $el = element.find('#checkout-errors')
          errors = _.omit(scope.errors.all, 'fieldErrors')
          overLimitError = $filter('translate')('checkout.cart_over_limit_error')
          validateItems = get(scope, ['validateItem', 'items'], []);
          if !isEmpty(validateItems) && validateItems.every((item) -> item.code == 'GIFT_VARIATION_REQUIRED')
            $el.hide()
          else if _.keys(errors).length > 0 || (scope.validateItem && scope.validateItem.message)
            $el.show()
            errorArray = _.values(errors)
            if mainConfig.isExceedCartLimitation
              errorArray = errorArray.concat(overLimitError)
            if scope.validateItem
              errorArray = errorArray.concat(scope.validateItem.message)
            htmlContent = errorArray.join('<br>')
            $el.html(htmlContent)
          else if mainConfig.isExceedCartLimitation
            $el.show()
            $el.html(overLimitError)
          else
            $el.hide()

        setCartTips = (bool) ->
          # get error message form js
          tips = []
          tips.push $filter('translate')('product.addon_products.tips.limit_exceed') if bool
          $el = element.find('#checkout-tips')
          $el.html(_.values(tips).join("<br/>"))
          if _.isEmpty(tips)
            $el.hide()
          else
            $el.show()

        toggleCheckoutButton = () ->
          isShowing = scope.state.isCartItemInvalid || scope.state.isCartLoading || _.chain(scope.errors).reject((value) -> _.isEmpty(value)).values().some((isInvalid) -> isInvalid).value()
          if multiCheckoutService.isEnabled()
            isShowing = isShowing || scope.multiCart.currentStep != CART_STEPS.SUMMARY
          element.find(".btn-checkout")[sprintf("%sClass", if isShowing then "add" else "remove")]("disabled")

        checkAddonSection = (cartItems) ->
          scope.show_cart_addon_section = _.some(cartItems, (item) -> item.type != 'redeem_gift')

        scope.hasItemError = () ->
          !isEmpty(scope.validateItem || scope.multiCart.errors)

        # Events

        scope.$watch "state.isCartLoading", (() -> toggleCheckoutButton())
        scope.$watch("errors", (() -> toggleCheckoutButton()), true)
        scope.$on CHECKOUT_EVENTS.CART.ITEMS.CHANGED, ($event, payload) ->
          validateCart(() -> reload(SECTION_NAME.ALL))
          scope.validateItem = payload.cartValidateItem
          scope.state.isCartItemInvalid = !payload.success

        scope.$on CHECKOUT_EVENTS.CART.ITEMS.CHANGING, (() ->
          element.find(".loading-icon").show()
          $('.shopping-cart button, .shopping-cart input').prop('disabled', true)
        )

        scope.$on CHECKOUT_EVENTS.CART.EMPTY, (() ->
          element.find(".shopping-cart-empty").removeClass("hide")
          element.find(".cart-content").hide()
        )
        scope.$on CHECKOUT_EVENTS.CART.FORM.CHANGED, ($event, options) ->
          validateCart () ->
            if options.rerender
              reload([SECTION_NAME.PROMOTIONS, SECTION_NAME.SUMMARY])
            else
              element.find(".order-summary .loading-icon").hide()
              element.find('.shopping-cart .loading-icon, .cart-addon .loading-icon').hide()
              element.find('.shopping-cart .cart-share-btn').removeClass('disable')

        scope.$on CHECKOUT_EVENTS.CART.PROMOTIONS.CHANGED, () ->
          reload([SECTION_NAME.PROMOTIONS, SECTION_NAME.SUMMARY])

        scope.$on CHECKOUT_EVENTS.CART.FORM.CHANGING, (() ->
          element.find(".order-summary .loading-icon").show()
          element.find('.shopping-cart .loading-icon, .cart-addon .loading-icon').show()
        )

        scope.$on CHECKOUT_EVENTS.CART.FORM.LOADED, () ->
          element.find(".order-form .loading-icon").hide()
          $timeout((() ->
            validateLocalData()
            setCartErrors()
          ), 50)

        scope.$on CHECKOUT_EVENTS.CART.DELIVERY.CHANGED, (() ->
          cartService.validate()
            .catch (response) ->
              scope.$broadcast(CHECKOUT_EVENTS.CART.ITEMS.VALIDATED, {
                success: false, cartValidateItem: response
              })
            .finally () ->
              validateCart () ->
                reload([SECTION_NAME.DESTINATION, SECTION_NAME.PROMOTIONS, SECTION_NAME.SUMMARY, SECTION_NAME.ITEMS])
        )

        scope.$on CHECKOUT_EVENTS.CART.DESTINATION.CHANGED, (() -> 
          validateCart () ->
            reload([SECTION_NAME.PROMOTIONS, SECTION_NAME.SUMMARY, SECTION_NAME.ITEMS])
        )

        scope.$on CHECKOUT_EVENTS.CART.STEP.NEXT, (_, { nextStep }) ->
          scope.multiCart.currentStep = nextStep
          if nextStep == CART_STEPS.SUMMARY
            scope.multiCart.summarySectionStatus = SECTION_STATUS.ACTIVE
          else
            scope.multiCart.summarySectionStatus = SECTION_STATUS.DEFAULT
          toggleCheckoutButton()

        scope.$on CHECKOUT_EVENTS.CART.CONTENT.LOADED, () ->
          if element.find(".shopping-cart .checkout-section-loading").length is 0
            element.find('.shopping-cart .loading-icon, .cart-addon .loading-icon').hide()
            element.find('.shopping-cart .cart-share-btn').removeClass('disable')

          action = if element.find(".cart-promotions .promotion").length > 0 then "show" else "hide"
          element.find(".cart-promotions")[action]()

        scope.$on CHECKOUT_EVENTS.CART.SUMMARY.LOADED, (() -> element.find(".order-summary .loading-icon").hide())
        scope.$on CHECKOUT_EVENTS.CART.SUMMARY.CHANGED, (($event) ->
          validateCart(() -> reload([SECTION_NAME.PROMOTIONS]))
        )
        scope.$on CHECKOUT_EVENTS.CART.COUPON.REMOVE, (($event, code) -> element.find(".order-summary .loading-icon").show())

        scope.$on CHECKOUT_EVENTS.CART.NOTHING.CHANGED, (($event, options) ->
          element.find(".order-form .loading-icon").hide()
          element.find(".order-summary .loading-icon").hide()
          element.find(".cart-addon .loading-icon").hide()
          shouldShowTips = options.code != 404 # handle item not found case (no need show error, just not change anything)
          setCartTips(shouldShowTips)
        )

        scope.$on CHECKOUT_EVENTS.CART.ITEM.UPDATED, (() ->
          setCartTips(false)
        )

        scope.validateLocalData = validateLocalData
        scope.setCartErrors = setCartErrors

        if slFeatureService.hasFeature('cart_addon')
          scope.$on CHECKOUT_EVENTS.CART.ITEM.REMOVED, (($event, data) ->
            checkAddonSection(data.items)
          )
          checkAddonSection(cart.items)
          checkoutService
            .requestPartial('cart', 'addons')
            .then (res) -> element.find('.cart-addon-container').replaceWith($compile(res.data)(scope))

        sendSlpixelTracking = (cart) ->
          cartItems = []
          pushItem = (item) ->
            if(!_.isObject(item) || !_.isObject(cart) || item.type == 'custom_discount')
              return
            price = cartService.getItemPrice(item)
            cartItems.push {
              productID: item.product_id,
              type: item.type,
              name: $filter('translateModel')(item.product.title_translations),
              currency: price && price.currency_iso,
              price: price && price.dollars,
              quantity: item.quantity,
              variationID: item.variation_id
            }

          if (Array.isArray(cart.items))
            cart.items.forEach (item) ->
              pushItem(item)
              if (Array.isArray(item.addon_items))
                item.addon_items.forEach (addonItem) ->
                  pushItem(addonItem)

          slPixelService.hdPageView(
            'cart', { cartItems: cartItems }
          )

        sendSlpixelTracking(cart)

        # hiido tracking order edit pageview
        hiidoTrackerService.orderEdit.pageView()
        
        # track initiateCheckout event for user enter cart page via link
        trackerService.fbInitiateCheckoutForCartLink(
          $rootScope.currentCart.getItems(),
          $rootScope.currentCart.getSubtotal()
        )
    }
])
